<template>
  <v-container>

    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto px-3 py-2"
        outlined
      >
        <v-row>
          <v-col>
            <v-btn
              color="warning"
              class="mr-4"
              @click="goBack()"
              small
            >
              <v-icon>mdi-keyboard-backspace</v-icon>&nbsp;Kembali
            </v-btn>

            <v-btn
              color="error"
              class="mr-4"
              @click="resetForm"
              v-if="!isEdit"
              small
            >
              <v-icon>mdi-restart</v-icon>&nbsp;Reset Form
            </v-btn>
      
          </v-col>
          <v-col class="text-right">

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              <v-icon>mdi-content-save</v-icon>&nbsp;Simpan
            </v-btn>
      
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
          <div class="subtitle-2 text-center grey lighten-2 px-2 py-2">NOTA DINAS</div>
           </v-col>
          <v-col>
              <table width="100%">
                <tr>
                  <td width="100px;" style="vertical-align:top;">Yth</td>
                  <td width="10px;" style="vertical-align:top;">:</td>
                  <td width="80%;" class="subtitle-2" style="vertical-align:top;">
                    <span v-if="tujuanSurat">
                    
                      <span v-if="tujuanSurat.length > 1">
                        <ul>
                          <li v-for="(item,i) in tujuanSurat" :key="i">
                            <span v-if="item.pos_name">{{item.pos_name}}</span>
                            <span v-else>{{item}}</span>
                            </li>
                        </ul>
                      </span>
                      <span v-else-if="tujuanSurat.length == 1">
                        <span v-if="tujuanSurat[0].pos_name">{{tujuanSurat[0].pos_name}}</span>
                        <span v-else>{{tujuanSurat[0]}}</span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td >Dari</td>
                  <td>:</td>
                  <td class="subtitle-2"><span v-if="vPenandatangan">{{ vPenandatangan ? vPenandatangan.pos_name : '-' }}</span></td>
                </tr>
                 <tr>
                  <td >Sifat</td>
                  <td>:</td>
                  <td class="subtitle-2"><span v-if="vSifat">{{ vSifat.sifat ? vSifat.sifat : '-' }}</span></td>
                </tr>
                 <tr>
                  <td >Hal</td>
                  <td>:</td>
                  <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.perihalSurat ? dataSurat.perihalSurat : '-' }}</span></td>
                </tr>
                <tr>
                  <td>Tgl Surat</td>
                  <td>:</td>
                  <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.tglSurat ? dataSurat.tglSurat : '-' | formatDate }}</span></td>
                </tr>
                <tr>
                  <td style="vertical-align:top;">Lampiran</td>
                  <td style="vertical-align:top;">:</td>
                  <td style="vertical-align:top;">
                    <v-textarea
                      v-model="dataSurat.lampiranSurat"
                      label="Lampiran"
                      auto-grow
                      rows="1"
                      counter
                    ></v-textarea>  

                  </td>
                </tr>
                
              </table>
              
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >

            <froala v-if="dataSurat" id="editFroala" :tag="'textarea'" :config="configFroala" v-model="dataSurat.isiSuratAtas"></froala>
            <!--<ejs-documenteditorcontainer :serviceUrl='serviceUrl' :enableToolbar='true'> </ejs-documenteditorcontainer>-->
          </v-col>

        </v-row>
       
        <v-row>
           <v-col
            cols="12"
            md="12"
          >

            <v-combobox
              v-model="tembusanSurat"
              :items="dataListUserTo"
              label="Tembusan"
              multiple
              chips
              @change="cekStatusTembusan()"
              item-value="value"
              item-text="text"
            ></v-combobox>

          </v-col>
        </v-row>
          <v-row>
          <v-col>
            <v-btn
              color="warning"
              class="mr-4"
              @click="goBack()"
              small
            >
              <v-icon>mdi-keyboard-backspace</v-icon>&nbsp;Kembali
            </v-btn>

            <v-btn
              color="error"
              class="mr-4"
              @click="resetForm"
              v-if="!isEdit"
              small
            >
              <v-icon>mdi-restart</v-icon>&nbsp;Reset Form
            </v-btn>
      
          </v-col>
          <v-col class="text-right">

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              <v-icon>mdi-content-save</v-icon>&nbsp;Simpan
            </v-btn>
      
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn  color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { splitComponentsByComma } from '@/utils/index'
import { insertDataSurat, getDataSuratByLetterGUID, updateDataSurat, printSuratbyGUID } from '@/api/datasurat'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
/*import { RichTextEditorPlugin, Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table } from "@syncfusion/ej2-vue-richtexteditor"*/
// import { DocumentEditorPlugin, DocumentEditorComponent, Print, SfdtExport, WordExport, TextExport, Selection, Search, Editor, ImageResizer, EditorHistory, ContextMenu, OptionsPane, HyperlinkDialog, TableDialog, BookmarkDialog, TableOfContentsDialog, PageSetupDialog, StyleDialog, ListDialog, ParagraphDialog, BulletsAndNumberingDialog, FontDialog, TablePropertiesDialog, BordersAndShadingDialog, TableOptionsDialog, CellOptionsDialog, StylesDialog } from '@syncfusion/ej2-vue-documenteditor';
// import { DocumentEditorContainerPlugin, DocumentEditorContainerComponent,Toolbar} from '@syncfusion/ej2-vue-documenteditor';

const defaultForm = {
  isiSuratAtas: '',
  lampiranSurat:'',
  tembusanSurat:'',
}

export default {
  name: 'FormEditor4',
  components: { 
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      dataSurat: {},
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 240 || 'Max 240 characters',
      },
      isLoading: false,
      dialogConfirm: false,
      dataUser: [],
      currrole: [],
      lGUID: '',
      serviceUrl:'http://localhost:62870',
      configFroala: {
        key: "eHE5C-11G2D2C1D2A5D5D-17jynH-9F4bC-22A8yunD5D4B3E3C3A6A5C2C4G5==",
        tabSpaces: 4,
        heightMin:600,
        toolbarButtons: {
          'moreText': {
            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
          },
          'moreParagraph': {
            'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
          },
          'moreRich': {
            'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', /*'emoticons', 'fontAwesome',*/ 'specialCharacters', /*'embedly', 'insertFile',*/ 'insertHR']
          },
          'moreMisc': {
            'buttons': ['undo', 'redo', 'fullscreen', /*'print', 'getPDF', 'spellChecker',*/ 'selectAll', 'html', 'help'],
            'align': 'right',
            'buttonsVisible': 2
          }
        },
        events: {
          initialized: function () {
            console.log('initialized')
          },
          "image.beforeUpload": function(files) {
            console.log('image.beforeUpload')
            var editor = this;
            if (files.length) {
              // Create a File Reader.
              var reader = new FileReader();
              // Set the reader to insert images when they are loaded.
              reader.onload = function(e) {
                var result = e.target.result;
                editor.image.insert(result, null, null, editor.image.get());
              };
              // Read image as base64.
              reader.readAsDataURL(files[0]);
            }
            editor.popups.hideAll();
            // Stop default upload chain.
            return false;
          }
        }
      },
      vPenandatangan: null,
      vSifat: null,
      tujuanSurat: null,
      tembusanSurat: null,
      dataListUserTo: []
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      var sdate = moment(value).lang("id").format('DD MMMM YYYY')
      return sdate
    }
  },
  provide:{
    // DocumentEditorContainer: [Toolbar]
  },
  created () {
    const vm = this

    vm.lGUID = vm.$route.params && vm.$route.params.lguid
    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)

    vm.dataUser = secureStorage.getItem('user')
    if (vm.dataUser !== null) {
      // console.log(vm.dataUser)
      if(vm.dataUser.role !== null) {
        if(vm.dataUser.role.id === 1) {
          vm.isAdmin = true
        }
      }
    }
    vm.getUsersToData()

  },
  destroyed () {
  },
  mounted () {
    const vm = this
    vm.fetchData(vm.lGUID)
  },
  computed: {

  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    resetForm () {
      this.$refs.form.reset()
    },
    getUsersToData() {
      const vm = this
      var rData = secureStorage.getItem('userposall')
      // console.log(rData)
      if (rData !== null) {
        let temp = []
        rData.forEach(element => {
          if (element != null) {
            if (element.rolename != 'admin' && element.rolename != 'developer' && element.rolename != 'admin_sdm' && element.rolename != 'sekretaris' && element.rolename != 'staff') {
              // console.log(element)
              if (element.prefix != null) { element.prefix = element.prefix + ' ' } else { element.prefix = '' }

              let uposid = element.id
              element['posname'] = element.prefix + element.pos_name
              element['userposname'] = element.fullname + ' (' + element['posname'] + ')'
              element['unitcode'] = parseInt(element.unit_code)

              let tmp = {text:  element['userposname'], value: uposid, unitcode: element['unitcode'], pos_name: element['posname']}
              temp.push(tmp)
            }
          }
        })
        
        temp.sort((a, b) => Number(a.unitcode) - Number(b.unitcode))
        vm.dataListUserTo = temp
        // console.log(vm.dataListUserTo)
      }
      
    },
    initStatusYth(){
      const vm = this
      var datauserposall = vm.dataListUserTo
      let tos = vm.dataSurat.tujuanSurat
      // console.log(tos)
      if(tos){
        
        tos = tos.toString()
        vm.tujuanSurat = []
        // let arrtos =splitComponentsByComma(tos)
        let arrtos = tos.split('|')
        // console.log(arrtos)
        if(arrtos.length > 0 && datauserposall.length > 0){
          // console.log(arrtos)
          arrtos.forEach(idtos => {
            let isIntid =  parseInt(idtos)
            if(isIntid > 0){
              let tmp = datauserposall.find(x => x.value === isIntid)
              if(tmp){
                vm.tujuanSurat.push(tmp)
              }
            }else{
              if(idtos != ''){
                vm.tujuanSurat.push(idtos)
              }
            }
          })
        }
      }
    
    },
    initStatusTembusan(){
      const vm = this
      var datauserposall = vm.dataListUserTo
      let tos = vm.dataSurat.tembusanSurat
      tos = tos.toString()
      // console.log(tos)
      if(tos){
        vm.tembusanSurat = []
        // let arrtos =splitComponentsByComma(tos)
        let arrtos = tos.split('|')
    
        if(arrtos.length > 0 && datauserposall.length > 0){
          // console.log(arrtos)
          arrtos.forEach(idtos => {
            let isIntid =  parseInt(idtos)
            if(isIntid > 0){
              let data = datauserposall.find(x => x.value === isIntid)
              if(data){
                vm.tembusanSurat.push(data)
              }
            }else{
              if(idtos != ''){
                vm.tembusanSurat.push(idtos)
              }
            }
          })
        }
      }
    // console.log(vm.tembusanSurat)
    },
    cekStatusTembusan(){
      const vm = this
      var array = vm.tembusanSurat
       // console.log('cekStatusTembusan',array)
      let tmp = []
      if(array){
        array.forEach(element => {
          if(element){
            if(element.value){
              tmp.push(element.value)
            }else if(element != ''){
              tmp.push(element)
            }
          }
        });
      }
      
      vm.dataSurat.tembusanSurat = tmp
      // console.log(vm.dataSurat.tembusanSurat)
    },
    fetchData (lguid) {
      const vm = this
      vm.isLoading = true
      getDataSuratByLetterGUID(lguid).then(response => {
        let data = response.data
        
        if(data.length > 0){
          vm.dataSurat = data[0]
          // console.log(vm.dataSurat)

          if(vm.dataSurat.hruserpositions_penandatangan){
            var datauserposall = secureStorage.getItem('userposall')
            vm.vPenandatangan = datauserposall.find(x => x.id === vm.dataSurat.hruserpositions_penandatangan.id)
          }

          if(vm.dataSurat.eosifatsurat){
            vm.vSifat = vm.dataSurat.eosifatsurat
          }

          vm.initStatusYth()
          vm.initStatusTembusan()

          delete vm.dataSurat.created_at
          delete vm.dataSurat.updated_at
        }
        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    
    saveEditForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        vm.cekStatusTembusan()
        vm.postForm['isiSuratAtas'] = vm.dataSurat.isiSuratAtas
        vm.postForm['lampiranSurat'] = vm.dataSurat.lampiranSurat
        
        let tos = vm.dataSurat.tembusanSurat
        if(tos){
          var strtos = tos.join("|");
          vm.postForm['tembusanSurat']  = strtos.toString()
        }
      
        vm.postForm.hruserpositions_editedBy = vm.currrole.id
        vm.postForm.userEditor = vm.dataUser.id

        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false
      var modelsurat = 4 // ND

      var idb = vm.dataSurat.id
      // console.log('id Data: ', idb)
      vm.isLoading = true
      // console.log('update:',vm.postForm)

      updateDataSurat(vm.postForm, idb).then(response => {
        // console.log('Data', response.data)
        var data = response.data
        vm.dataSurat = data

        vm.isLoading = true
        printSuratbyGUID(modelsurat, vm.lGUID).then(response => {
          // console.log('printSuratbyGUID Data', response.data)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'green', text: 'Generate PDF BERHASIL.', timeout: 2000
          }
          vm.goBack()
        })
        .catch(err => {
          // console.log(err)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'red', text: 'Generate PDF GAGAL, silakan dicoba kembali.', timeout: 2000
          }
        })
        
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    
  }
}

</script>
